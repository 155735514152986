<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-list>
				<v-list-tile avatar>
					<v-list-tile-avatar color="primary">{{ initials }}</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title>{{ fullname || email }}</v-list-tile-title>
						<v-list-tile-sub-title v-if="fullname">{{ email }}</v-list-tile-sub-title>
					</v-list-tile-content>
				</v-list-tile>
				<v-divider />
			</v-list>
		</v-flex>
		<v-flex scroll-y>
			<ProfileSettings />
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import UserProfileModuleGuard from '@/mixins/ModulesGuards/UserProfile/UserProfileModuleGuard'

export default {
	name: 'ProfileSideBar',
	components: {
		ProfileSettings: () => ({
			component: import('@/components/Profile/ProfileSettings')
		})
	},
	mixins: [UserProfileModuleGuard],
	computed: {
		...mapState({
			email: state => state.user.email,
			fullname: state => state.user.fullname,
			initials: state => state.user.initials
		})
	}
}
</script>
