<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<ProfileBreadcrumbs />
		</v-flex>
		<v-flex scroll-y>
			<router-view></router-view>
		</v-flex>
	</v-layout>
</template>

<script>
import UserProfileModuleGuard from '@/mixins/ModulesGuards/UserProfile/UserProfileModuleGuard'
import ProfileSideBar from '@/components/Profile/ProfileSideBar'
import Vue from 'vue'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";

export default {
	name: 'ProfileModule',
	components: {
		ProfileBreadcrumbs: () => ({
			component: import('@/components/Profile/ProfileBreadcrumbs')
		})
	},
	mixins: [
		UserProfileModuleGuard,
		HandleSideBarMixin,
	],
	created: function () {
		const SideBarClass = Vue.extend(ProfileSideBar)
		const sideBarComponent = new SideBarClass({
			parent: this
		})
		this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
			canMinimize: true,
			moduleInstance: sideBarComponent,
			emitter: this
		})
	},
	mounted() {
		this.setHasSidebar(true);
		this.setShowSidebar(true);
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this);
		this.setHasSidebar(false);
		this.setShowSidebar(false);
	}
}
</script>
